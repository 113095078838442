.wrapErrorContent {
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapError {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 32px 64px;
    margin-top: 150px;
    .wrapErrorText {
      display: flex;
      flex-direction: column;
      align-items: center;
      .notFoundText {
        color: #596980;
      }
      .fofText {
        color: #596980;
      }
      .errorText {
        color: #C4CFDD;
      }
    }
  }
}